<template>
  <ImagePopup v-if="bannerPopupVisible" :image-url="getCompanyDetailsLocal?.banner"
              :on-close-popup="showBannerPopup"/>
  <div key="SignInPageDesktopView" class="xsm:hidden lg:grid grid-cols-2">
    <div class="bg-brand-color relative">
        <img v-if="getCompanyDetailsLocal?.banner" alt="" class="object-cover h-screen w-full object-top" :src="getCompanyDetailsLocal?.banner"/>
        <CompanyImage v-else :company-image="getCompanyDetailsLocal?.banner" class="h-screen w-full"/>
        <div v-if="getCompanyDetailsLocal?.banner" @click="showBannerPopup(true)"
             class="bottom-5 right-5 w-10 h-10 bg-brand-color-400 rounded-full absolute flex flex-col justify-center items-center cursor-pointer">
          <Icon name="material-symbols:fullscreen-rounded"
                class="text-white w-6 h-6 mx-auto hover:w-7 hover:h-7 transition duration-100"/>
        </div>
    </div>
    <!--        <div class="bg-brand-color">-->
    <!--            <div v-if="getCompanyImage">-->
    <!--                <img alt="" class="object-cover h-screen w-full object-top" :src="getCompanyImage"/>-->
    <!--            </div>-->
    <!--            <CompanyImage v-else :company-image="getCompanyImage" class="h-screen w-full"/>-->
    <!--        </div>-->
    <div class="flex min-h-screen max-h-screen bg-brand-color flex-1 flex-col justify-start overflow-y-scroll">
      <!--Slot-->
      <slot/>
    </div>
  </div>
  <div class="hidden">
    <div class="z-0">
      <div v-if="getCompanyImage">
        <img alt="" class="object-cover h-[500px] w-screen object-top" :src="getCompanyImage"/>
      </div>
      <CompanyImage v-else :company-image="getCompanyImage" class="h-[500px] w-screen"/>
    </div>
    <div class="w-full z-10 -mt-44 bg-gradient-to-t from-brand-color from-70%">
      <div class="flex min-h-full flex-col xsm:col-span-5 xsm:px-4 sm:col-span-5 md:col-span-3 justify-center">
        <div class="xsm:w-full sm:w-full md:w-4/5 mt-5 mb-20 md:mx-auto">
          <div
              class="col-span-3 flex min-h-full flex-1 flex-col justify-center py-12 xsm:px-0 lg:px-8 xsm:pt-5 md:py-16">
            <div class="mt-5 xsm:mx-auto sm:w-full sm:max-w-[480px]">
              <!--Slot-->
              <div>
                <slot/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterForSignIn custom-classes="left-0"/>
    </div>
  </div>
  <div class="flex flex-col lg:hidden">
    <div class="absolute top-2 right-3 z-10">
    </div>
    <div class="z-0">
      <div v-if="getCompanyDetailsLocal?.banner">
        <img alt="" :class="clickedViewFlyer?'h-full':'h-[500px]'" class="object-cover w-screen object-top"
             :src="getCompanyDetailsLocal?.banner"/>
      </div>
      <div v-else class="h-[500px] bg-brand-color"></div>
    </div>
    <div :class="clickedViewFlyer?'mt-0 to-brand-color-400':'-mt-44'"
         class="bg-gradient-to-t from-brand-color from-50% w-full z-10 transition-all	ease-in delay-400">
      <div class="flex min-h-full flex-col xsm:col-span-5 xsm:px-4 sm:col-span-5 md:col-span-3 justify-center">
        <div class="xsm:w-full sm:w-full md:w-4/5 mt-5 md:mx-auto">
          <div class="col-span-3 flex min-h-full flex-1 flex-col justify-center">
            <div class="mt-5 w-full ">
              <div v-if="!clickedViewFlyer" class="flex flex-row mb-10 text-white justify-center items-center">
                <button @click="viewFullFlyer" class="  ">
                  <p class="text-white font-poppins text-xs bg-brand-color-700 px-2 py-1 rounded-md">
                    {{ ('VIEW FULL FLYER') }}</p>
                  <ChevronDownIcon class="mx-auto h-5 w-5 text-white" aria-hidden="true"/>
                </button>
              </div>
              <!--Slot-->
              <div>
                <slot/>
              </div>
            </div>

          </div>
        </div>

      </div>
      <FooterForSignIn custom-classes="left-0"/>

    </div>

  </div>
  <FooterForSignIn width="lg:w-1/2" custom-classes="right-0"/>
  <Notification/>
</template>

<script setup lang="ts">
import CompanyImage from "~/components/Image/CompanyImage.vue";
import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import moment from "moment";


const useCompanyStore = companyStore();
const {getCompanyName, getCompanyImage, getCompanyLogo, getCompanyDetailsLocal} = storeToRefs(useCompanyStore)
useHead({
  title: "Aphelia | " + (getCompanyDetailsLocal.value?.name ?? "")
})

const bannerPopupVisible = ref<boolean>(false);
let clickedViewFlyer = ref<boolean>(false);
const showBannerPopup = (value: boolean) => {
  bannerPopupVisible.value = value;
}
const viewFullFlyer = () => {
  clickedViewFlyer.value = true;
}
</script>